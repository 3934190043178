@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.terms-and-conditions-modal {
  text-align: left;
  max-width: $breakpoint-md;
  padding-bottom: 0;

  @media #{$mq-min-sm} {
    margin: ($margin * 2) auto;
  }
  @media #{$mq-min-md} {
    height: calc(100% - 64px);
    max-height: 550px;
  }

  .modal-body {
    &::after {
      bottom: 0;
    }

    @media #{$mq-min-md} {
      height: 93%;
      overflow: auto;
      padding: ($padding * 2.25) ($padding * 2.25) 56px ($padding * 2.25);
    }
    
    @media #{$mq-min-md} {
      overflow: hidden;

      &::after {
        content: none;
      }
    }
  }

  .flex-row {
    @extend %fade-content-out;
    margin: 0;
    display: block;
    height: 100%;

    &::after {
      content: none;
    }

    @media #{$mq-min-md} {
      overflow-y: hidden;
      display: flex;

      &::after {
        content: '';
        bottom: 0;
      }
    }
  }

  .flex-item {
    padding: 0;

    @media #{$mq-min-sm} {
      margin: ($margin * 2) 0;
    }
  }

  &-content {
    @extend %fade-content-out;

    @media #{$mq-max-sm} {
      &::after {
        display: none;
      }
    }

    &-overflow {
      @media #{$mq-min-md} {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 100%;
        padding: 0 ($padding * 2);

        > :first-child {
          margin-top: 0;
        }
      }
    }
  }

  &-header {
    color: $color-blue;

    @media #{$mq-min-md} {
      margin-right: $margin * 2;

      &.flex-item {
        flex-basis: 0;
        max-width: 320px;
      }
    }

    &-title {
      margin-bottom: $margin * 1.5;
    }

    &-subtitle {
      color: black !important;
    }

    &-content {
      font-size: $font-size-lg;
      line-height: 1.5;
    }
  }

  &-download {
    margin-bottom: $margin * 2;

    @media #{$mq-min-sm} {
      margin-bottom: 0;
    }

    .text-button {
      padding-top: 0;
      text-align: left;
      font-size: $font-size-md;
      font-weight: bold;
      line-height: 1.5;
    }

    .icon {
      margin-left: -($margin / 2);
    }
  }

  &-quicklook {
    color: $color-gray;

    &.flex-item {
      flex-basis: 0;
    }

    &-title {
      margin-bottom: $margin * 2;
      color: $color-black;
    }
  }
}

.center {
  text-align: center;
}

.padding-left {
  margin-left: 38px !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.bold {
  font-weight: bold
}

.margin-right-30 {
  margin-right: 30px;
}