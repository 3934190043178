@import '~texkit/dist/styles/variables';

.deactivate-user-modal {
  .modal-title,
  .modal-content {
    max-width: 62 * 8px;
  }

  .modal-title {
    color: $color-red-dark;
  }
}
