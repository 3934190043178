@import '~texkit/dist/styles/variables';

.takeover-page-header {
  $progress-bar-height: 1px;

  position: relative;
  color: $color-gray;
  font-size: $font-size-sm;
  padding-bottom: $padding;
  margin-top: (-$margin * 2);
  margin-bottom: $margin * 3;
  border-bottom: solid $progress-bar-height $color-gray-light;

  @media #{$mq-min-sm} {
    margin-top: (-$margin * 3);
    margin-bottom: $margin * 4;
  }

  .progress-bar {
    position: absolute;
    bottom: -$progress-bar-height;
  }

  &-step-count {
    margin-top: 0;
    margin-bottom: $margin * 2;

    @media #{$mq-min-sm} {
      margin-bottom: $margin * 2.5;
    }
  }
}
