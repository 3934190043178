@import '~texkit/dist/styles/variables';

.toggle-group {
  position: relative;

  &-switch {
    position: absolute;
    top: -($margin / 2);
    right: -($margin / 2);
    padding: ($padding / 2);
    color: $color-gray;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-decoration: none;

    @media #{$mq-min-sm} {
      font-size: $font-size-md;
    }
  }
}
