@import '~texkit/dist/styles/variables';

.link-service-lookup-form {
  &-body {
    @media #{$mq-min-sm} {
      margin: 0;
      max-width: 336px;
    }
  }

  .input-wrapper .input-wrapper-field {
    max-width: 336px;
  }

  .input-ssn .input-wrapper-field {
    max-width: 112px;
  }
}
