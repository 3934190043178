.password-helper-container {
    margin-top: 0;
    margin-bottom: 1em;
}
.input-margin {
    margin-bottom: 0;
}
.password-helper-item {
    width: 100%;
    color: #ffffff;
    background-color: #20294f;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 10px 10px 5px 10px;
}
.password-helper-text {
    list-style-type: none;
}
.password-helper-check {
    color: #20294f;
    padding-right: 10px;
}
.password-helper-checked {
    color: #1cd284;
    font-weight: bolder;
    padding-right: 10px;
}
