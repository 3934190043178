@import "~texkit/dist/styles/variables";

.employee-agency-card {
  .card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-text {
    // fix needed for IE11, do not remove
    width: 100%; // https://stackoverflow.com/questions/35111090/text-in-a-flex-container-doesnt-wrap-in-ie11
  }

  .h4 {
    margin-bottom: $margin / 4;
  }

  p {
    margin: ($margin * 0.25) 0 0 0;
    color: $color-gray;
  }
}
