@import '~texkit/dist/styles/variables';

$component: '.takeover-nav';

#{$component} {
  border-bottom: $border-default;
  background-color: $color-gray-lightest;

  &-container,
  &-container.container {
    display: flex;
    align-items: center;
    min-height: 72px;
    padding-top: $padding * 1.25;
    padding-bottom: $padding;
  }

  &-spacer {
    width: 1px;
    height: 36px;
    margin: 0 $margin;
    background-color: $color-gray-lighter;
  }

  &-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-subtitle {
    margin: 0;
    font-size: inherit;
    font-weight: 500;
  }

  &-title {
    margin: 0;

    .takeover-nav-subtitle + & {
      font-weight: 700;
      font-size: $font-size-lg;
    }
  }

  &-logo {
    height: 33px;
    overflow: hidden;

    .logo {
      color: $color-blue-dark;
    }

    @media #{$mq-max-sm} {
      width: 36px;
      overflow: hidden;
    }
  }
}
