@import "~texkit/dist/styles/variables";

.service-icon {
  svg {
    display: block;
    width: 100%;
    height: auto;
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
}
