@import '~texkit/dist/styles/variables';

.verify-address-modal {
  &-label {
    width: 100%;
    color: $color-gray;
    text-align: left;
    font-size: $font-size-md;
    display: inline-block;
    margin: ($margin * 2) 0 $margin;
  }

  .input-radio {
    margin-top: 0;

    .input-wrapper-field {
      align-items: center;
    }

    .input-label {
      font-size: $font-size-lg;
    }

    .input {
      top: -4px;
    }
  }

  .modal-title {
    max-width: 590px;
  }

  .modal-content {
    color: $color-black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
