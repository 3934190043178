@import '~texkit/dist/styles/variables';

.server-error-modal {
  .modal-graphic {
    max-width: 300px;
  }

  .highlight {
    color: #FF0381;
  }
}
