@import '~texkit/dist/styles/variables';

.input-checkbox {
  $checkbox-size: 24px;

  margin: $margin 0;
  border: 0 solid transparent;
  border-radius: 4px;

  .input-label {
    padding: 1px 0 0 ($padding * 2.5);
    font-size: $font-size-base;
    line-height: 1.5;
    cursor: pointer;

    a {
      color: $color-white;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      margin: 0;
      padding: 0;
      border-radius: 4px;
      appearance: none;
      background-color: transparent;
      border: solid 2px $color-gray-light;
      cursor: pointer;
      transition: $transition-fast;
    }
  }

  .input {
    position: absolute;
    opacity: 0;
    width: $checkbox-size;
    height: $checkbox-size;
    margin: 0;

    &:focus ~ .input-label {
      &::before {
        outline: none;
        box-shadow: 0 0 1px 3px #2d94f3;
        border-color: $color-blue;
      }
    }

    &:checked ~ .input-label {
      &::before {
        border-color: $color-blue;
        background-color: $color-blue;
      }
    }
  }

  .input-message {
    padding-left: 42px;
  }

  &-check {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: $checkbox-size;
    height: $checkbox-size;
    line-height: 22px;
    pointer-events: none;
    text-align: center;
    overflow: hidden;

    .icon-check {
      font-size: $checkbox-size;
      color: $color-white;
      opacity: 0;
      transform: scale(0.5, 0.5);
      transition: $transition-fast;
    }

    .input:checked ~ & .icon-check {
      opacity: 1;
      transform: scale(1, 1);
      visibility: visible;
    }
  }

  &.hasError {
    color: $color-white;
    padding: 6px 8px 8px;
    border-width: 2px;
    border-color: $color-red-dark;
    background-color: $color-red-dark;

    .input-label::before {
      border-color: $color-white;
    }

    .input-label {
      padding-top: 0;
    }
  }

  &-inverse {
    .input-label {
      color: $color-white;

      &::before {
        border-color: $color-white;
      }
    }

    .input {
      &:focus ~ .input-label {
        &::before {
          border-color: $color-white;
        }
      }

      &:checked ~ .input-label {
        &::before {
          border-color: $color-white;
          background-color: $color-white;
        }
      }
    }

    .input-checkbox-check {
      line-height: $checkbox-size;

      .icon-check {
        color: $color-blue;
      }
    }
  }
}
