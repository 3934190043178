@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.footer {
  display: block;
  font-size: $font-size-sm;
  color: $color-white;
  background: $color-blue-dark;
  padding: ($padding * 2) 0;

  @media #{$mq-min-sm} {
    padding: ($padding * 3.5) 0;
  }
  @media #{$mq-min-lg} {
    padding: 0;
  }

  .container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$mq-min-lg} {
      min-height: $footer-height;
      align-items: center;
    }
  }

  &-nav,
  &-copyright {
    flex: auto;
  }

  &-nav {
    display: flex;
    flex-grow: 0;
    padding-right: $padding;

    @media #{$mq-min-lg} {
      order: 2;
      padding: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
    }

    &-list {
      display: flex;
      flex-flow: column;
      padding: 0;
      margin-top: -$margin;
      list-style: none;
      align-items: flex-start;

      @media #{$mq-min-sm} {
        flex-flow: row;
        align-items: center;
        margin: 0 -$padding;
      }
      @media #{$mq-min-md} {
        margin: 0 (-$padding * 1.25);
      }
      @media #{$mq-max-xs} {
        align-items: flex-start;
      }

      &-item {
        margin: 0;

        a,
        button {
          text-align: left;
          padding: ($padding / 2) 0;
          transition: $transition-fast;

          &,
          &:hover {
            color: $color-white;
            text-decoration: underline;
            background: none;
          }

          @media #{$mq-min-sm} {
            padding: ($padding / 2) $padding;
            &.language-switcher-toggle {
              &:hover {
                background-color: darken($color-blue, 15%);
              }
            }
          }
          @media #{$mq-min-md} {
            padding: ($padding / 2) ($padding * 1.25);
          }

          @media #{$mq-min-lg} {
            display: block;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &-copyright {
    flex-basis: 100%;
    margin-bottom: $margin * 4;

    @media #{$mq-min-sm} {
      margin-bottom: $margin * 3;
    }
    @media #{$mq-min-lg} {
      flex-basis: auto;
      order: 0;
      margin-bottom: 0;
      padding: $margin 0;
    }

    .state-of-texas-logo {
      display: block;
      width: 32px;
      height: 32px;
      margin-right: $margin;
      margin-bottom: $margin;

      @media #{$mq-min-sm} {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
      @media #{$mq-min-lg} {
        margin-right: $margin * 1.5;
      }
    }
  }
}
