@import '~texkit/dist/styles/variables';

.verify-contact-modal {
  max-width: 678px;

  .form-error {
    max-width: 432px;
    margin-top: $margin * 3;
    margin-bottom: -$margin;
  }
}
