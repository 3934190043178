@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.dropdown {
  position: relative;
  display: inline-block;

  &.isOpen {
    z-index: $z-index-dropdown;
  }

  &-menu {
    position: absolute;
    list-style: none;
    padding: ($padding * 0.5) 0;
    min-width: 170px;
    color: $color-black;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);
      border-radius: $border-radius;
    }

    &-item {
      position: relative;
      padding: 0;
      box-sizing: border-box;

      &-action {
        @extend %button-reset;
        display: block;
        width: 100%;
        padding: ($padding * 0.75) ($padding * 1.5);
        line-height: 1.25;
        text-align: left;
        white-space: nowrap;
        color: $color-black;
        box-sizing: border-box;

        &,
        &:hover {
          text-decoration: none;
        }

        &:focus {
          background-color: darken($color-gray-lightest, 1%);
        }
        &:hover {
          background-color: darken($color-gray-lightest, 5%);
        }
      }

      .icon-check {
        display: none;
      }
    }
  }

  &-menu-position-bottom-left &-menu {
    transform-origin: 0 0;
    top: 100%;
    left: 0;
    margin-top: 0;

    &:before {
      transform-origin: 0 0;
    }
  }

  &-menu-position-bottom-right &-menu {
    transform-origin: 0 100%;
    top: 100%;
    right: 0;
    margin-top: 0;

    &:before {
      transform-origin: 100% 0;
    }
  }

  &-menu-position-top-right &-menu {
    transform-origin: 100% 100%;
    bottom: 100%;
    right: 0;
    margin-bottom: 0;

    &:before {
      transform-origin: 100% 100%;
    }
  }

  &-menu-position-top-left &-menu {
    transform-origin: 0 100%;
    bottom: 100%;
    left: 0;
    margin-bottom: 0;

    &:before {
      transform-origin: 0 100%;
    }
  }

  &.hasCheck {
    .icon-check {
      display: block;
    }
  }
}

// Transitions

// Off State
.dropdown-menu-enter,
.dropdown-menu-exit.dropdown-menu-exit-active {
  opacity: 0;
  overflow: hidden;
  transition: $transition-dropdown;

  &::before {
    transform: scale(0.75);
    border-radius: $border-radius * 4;
    transition: $transition-dropdown;
  }

  .dropdown-menu-item {
    transition: $transition-dropdown;
  }

  .dropdown-menu-position-bottom-left & {
    transform: translate(0, -24px);

    .dropdown-menu-item {
      transform: translate(-20px, -20px);
    }
  }

  .dropdown-menu-position-bottom-right & {
    transform: translate(0, -24px);

    .dropdown-menu-item {
      transform: translate(20px, -20px);
    }
  }

  .dropdown-menu-position-top-right & {
    transform: translate(0, 24px);

    .dropdown-menu-item {
      transform: translate(20px, 20px);
    }
  }

  .dropdown-menu-position-top-left & {
    transform: translate(0, 24px);

    .dropdown-menu-item {
      transform: translate(-20px, 20px);
    }
  }
}

// On State
.dropdown-menu-enter.dropdown-menu-enter-active,
.dropdown-menu-exit {
  opacity: 1;
  overflow: visible;
  transform: translate(0, 0);
  transition: $transition-dropdown;

  &::before {
    transform: scale(1);
    border-radius: $border-radius;
    transition: $transition-dropdown;
  }

  .dropdown-menu-item {
    transform: translate(0, 0);
    transition: $transition-dropdown;
  }
}

// While Leaving & Entering
.dropdown-menu-enter,
.dropdown-menu-enter.dropdown-menu-enter-active,
.dropdown-menu-exit,
.dropdown-menu-exit.dropdown-menu-exit-active {
  pointer-events: none;

  .dropdown-menu-item-action {
    transition: none;
  }
}
