@import "~texkit/dist/styles/variables";
@import "~texkit/dist/styles/placeholders";


.break{
  margin-bottom: 5px;
}



.advanced-security-qr-modal {
  text-align: center;
  max-width: $breakpoint-md;
  padding-bottom: 0;
}
.qr-code-button {
  padding: 0 70px 0 70px;
}
.advanced-security-delete-modal {
  text-align: center;
  max-width: $breakpoint-md;
  padding-bottom: 0;
  &-title {
    font-size: 40px;
  }
  &-warning {
    color: #47525d;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    padding-bottom: 64px;
  }
  &-button {
    margin-left: 32px;
  }
}
.advanced-security-modal {
  text-align: center;
  max-width: $breakpoint-md;
  padding-bottom: 0;
  &-desc {
    color: #47525d;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 20px;
  }
  &-select {
    color: #47525d;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 20px;
  }
  &-qr-desc {
    color: #47525d;
    font-size: 16px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 20px;
  }
  &-qr-desc {
    color: #47525d;
    font-size: 16px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  &-qr-button {
    margin-top: 30px;
  }

  &-radio-buttons {
    width: 500px;

    margin: auto;
  }
  .buttons-section-desc {
    display: inline-block;
  }
  .buttons-section-icon {
    padding-left: 330px;
  }

  .buttons-section-desc2 {
    display: inline-block;
    padding-right: 285px;
  }
  .buttons-section-icon2 {
    padding-top: 50px;
  }

  @media #{$mq-min-sm} {
    margin: ($margin * 2) auto;
  }
  @media #{$mq-min-md} {
    height: calc(100% - 64px);
    max-height: 550px;
  }

  .modal-body {
    &::after {
      bottom: 0;
    }

    @media #{$mq-min-md} {
      height: 93%;
      overflow: auto;
      padding: ($padding * 2.25) ($padding * 2.25) 56px ($padding * 2.25);
    }

    @media #{$mq-min-md} {
      overflow: visible;

      &::after {
        content: none;
      }
    }
  }

  .flex-row {
    @extend %fade-content-out;
    margin: 0;
    display: block;
    height: 100%;

    &::after {
      content: none;
    }

    @media #{$mq-min-md} {
      overflow-y: hidden;
      display: flex;

      &::after {
        content: "";
        bottom: 0;
      }
    }
  }

  .flex-item {
    padding: 0;

    @media #{$mq-min-sm} {
      margin: ($margin * 2) 0;
    }
  }

  &-content {
    @extend %fade-content-out;

    @media #{$mq-max-sm} {
      &::after {
        display: none;
      }
    }

    &-overflow {
      @media #{$mq-min-md} {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 100%;
        padding: 0 ($padding * 2);

        > :first-child {
          margin-top: 0;
        }
      }
    }
  }

  &-header {
    color: $color-blue;

    @media #{$mq-min-md} {
      margin-right: $margin * 2;

      &.flex-item {
        flex-basis: 0;
        max-width: 320px;
      }
    }

    &-title {
      margin-bottom: $margin * 1.5;
    }

    &-subtitle {
      color: black !important;
    }

    &-content {
      font-size: $font-size-lg;
      line-height: 1.5;
    }
  }

  &-download {
    margin-bottom: $margin * 2;

    @media #{$mq-min-sm} {
      margin-bottom: 0;
    }

    .text-button {
      padding-top: 0;
      text-align: left;
      font-size: $font-size-md;
      font-weight: bold;
      line-height: 1.5;
    }

    .icon {
      margin-left: -($margin / 2);
    }
  }

  &-quicklook {
    color: $color-gray;

    &.flex-item {
      flex-basis: 0;
    }

    &-title {
      margin-bottom: $margin * 2;
      color: $color-black;
    }
  }
}

.center {
  text-align: center;
}

.padding-left {
  margin-left: 38px !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.bold {
  font-weight: bold;
}

.margin-right-30 {
  margin-right: 30px;
}
