@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/mixins';
@import '~texkit/dist/styles/placeholders';

.payment {
  &-card {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-weight: 500 !important;
    margin: $margin 0 0;
    padding: 0 0 $padding 0;

    @media #{$mq-min-sm} {
      align-items: center;
      flex-direction: row;
      flex-basis: 90%;
    }

    .credit-card-icon {
      margin: 0 0 ($margin * 2) 0;

      @media #{$mq-min-sm} {
        margin: 0;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-info {
      display: flex;
      align-items: flex-end;

      @media #{$mq-min-sm} {
        margin: 0 $margin 0 ($margin * 2);
        flex: 1;
      }
    }

    &-name {
      color: $color-gray;
    }

    &-number {
      font-weight: bold;
      font-size: $font-size-lg;
      margin-top: $margin / 2;
    }

    &-expiration {
      font-size: $font-size-lg;
      letter-spacing: 0.04em;
      color: $color-gray;
    }

    .credit-card-icon {
      font-size: 56px;
    }
  }

  &-select {
    &-account {
      &-title {
        margin-top: 0;
      }
    }

    &-card {
      margin: 0;
      padding: 0;
    }

    &-list {
      &-item {
        margin: 0;
        padding: 0 $padding $padding 0;
        border-bottom: 1px solid $color-gray-lighter;

        @media #{$mq-min-sm} {
          padding: 0 $padding $padding $padding;
        }

        &:last-child {
          border-bottom: 1px solid $color-gray-lighter;
        }

        .input-wrapper-field {
          display: flex;
          align-items: center;
        }

        .input-label {
          width: 100%;
          padding-top: 0;

          &:before {
            top: 36px;
          }

          &:after {
            top: 48px;
          }
        }

        .payment-select-card {
          margin: 0;
          padding: 0;
        }
      }

      .input-radio-group {
        margin-bottom: 0;
      }
    }

    .payment-card-info {
      margin-left: -38px;
      @media #{$mq-min-sm} {
        margin-left: ($margin * 2);
      }
    }

    .payment-add {
      @media #{$mq-min-sm} {
        padding: 0 ($padding * 1.5) 0 ($padding * 3.5);
      }
    }
  }

  &-list {
    &-item {
      align-items: left;
      border-bottom: 1px solid $color-gray-lighter;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;

      @media #{$mq-min-sm} {
        flex-direction: row;
        align-items: center;
      }

      &-icon {
        flex-basis: 10%;
      }

      &-more {
        color: $color-gray;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }

  &-add {
    @extend %button-reset;

    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 0;

    &-wrapper {
      font-weight: 500 !important;
      margin: $margin 0;
      padding: 0 0 $padding 0;
      border-bottom: 1px solid $color-gray-lighter;
    }

    .credit-card-icon {
      font-size: 56px;
    }

    &-text {
      margin-left: $margin;

      @media #{$mq-min-sm} {
        margin-left: $margin * 2;
      }
    }

    &-icon {
      @include resizeIcon(40px);
      color: $color-green-light;

      @media #{$mq-min-sm} {
        margin-right: $margin;
      }
    }
  }

  &-review {
    &-subtitle {
      margin-top: 0;
    }
  }
}
