@import '~texkit/dist/styles/variables';

.input-radio-group {
  text-align: left;
  margin-top: $margin * 2;
  margin-bottom: $margin * 2;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  p + & {
    margin-top: -$margin / 2;

    @media #{$mq-min-sm} {
      margin-top: -$margin;
    }
  }

  & + p {
    margin-top: $margin * 3;
  }

  &-label {
    margin: 0 0 $margin;
  }

  &-error {
    span {
      display: inline-block;
      text-align: left;
      margin: ($margin * 0.75) auto;
      padding: ($padding * 0.5) $padding;
      color: $color-white;
      background-color: $color-red-dark;
      border-radius: $input-border-radius;
    }
  }

  .input-radio {
    margin-top: $margin * 1.25;
    margin-bottom: $margin * 1.25;
  }

  &-inline {
    .input-radio-group-label {
      margin-bottom: $margin / 2;
    }

    .input-radio {
      display: inline-block;
      width: auto;
      margin: ($margin / 2) ($margin * 2) ($margin / 2) 0;
    }

    &-spaced-evenly {
      display: flex;
      justify-content: space-around;

      .input-radio {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
