@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.modal {
  position: relative;
  box-sizing: border-box;
  background: $color-white;
  color: $color-black;
  width: auto;
  min-width: 320px;
  max-width: 854px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 40px 80px -40px rgba(0, 0, 0, 0.7);

  @media #{$mq-min-sm} {
    width: auto;
    height: auto;
    margin: ($margin * 2) auto;
    padding: 0;
    border-radius: $border-radius;
    overflow: visible;
  }

  &-sm {
    @media #{$mq-min-sm} {
      max-width: 65 * 8px;
    }

    .modal-body {
      padding-bottom: ($padding * 3.5);
    }
  }

  &-body {
    max-height: inherit;
    padding: ($padding * 4) ($padding * 2) ($padding * 4);

    @media #{$mq-min-sm} {
      padding: ($padding * 4.5) ($padding * 3) ($padding * 3);
    }
    @media #{$mq-min-md} {
      padding: ($padding * 4.5);
    }
  }

  &-close {
    z-index: 1;
    position: absolute;
    top: $margin * 0.75;
    right: $margin * 0.75;
    color: $color-black;
  }

  &-container {
    position: fixed;
    max-width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: $z-index-modal;
    overflow: hidden;

    @media #{$mq-min-sm} {
      overflow-y: auto;
      padding: 0 ($padding * 2);
    }
  }

  &-overlay {
    z-index: $z-index-modal - 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &-title,
  &-content,
  &-graphic {
    margin-left: auto;
    margin-right: auto;
  }

  & &-title {
    color: $color-blue;
    margin-top: 0;
  }

  &-content {
    color: $color-gray;

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  &-graphic {
    display: block;
    width: 100%;

    svg {
      height: auto;
    }
  }

  .form-actions {
    margin-bottom: 0;
  }
}

// Transitions
.modal-container {
  opacity: 0.01;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: scale(1.05) translate3d(0, -$padding, 0);
}
.modal-overlay {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.01;
}

body.modalActive {
  overflow: hidden;
  position: fixed;

  @media #{$mq-min-sm} {
    position: relative;
  }

  .modal-container {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  .modal-overlay {
    opacity: 1;
  }

  // Off State
  .modal-exit.modal-exit-active {
    opacity: 0.01;
    transform: scale(1.05) translate3d(0, -$padding, 0);

    & + .modal-overlay {
      opacity: 0.01;
    }
  }

  // On State
  .modal-enter.modal-enter-active,
  .modal-exit {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);

    & + .modal-overlay {
      opacity: 1;
    }
  }
}
