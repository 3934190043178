@import '~texkit/dist/styles/variables';

.form-error {
  text-align: left;
  margin: ($margin * 2) auto;
  padding: $padding ($padding * 1.5);
  font-size: $font-size-base;
  line-height: $line-height-default;
  color: $color-white;
  background-color: $color-red-dark;
  border-radius: $input-border-radius;
}
