@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.form {
  @extend %trim-margins;

  &-error {
    margin: 32px 0 -32px;
  }
}
