@import '~texkit/dist/styles/variables';

.todo-list {
  $breakpoint: $mq-min-md;

  &-card {
    border: 3px solid $color-yellow-dark;
    padding: 0 $padding*2;

    &.expired {
      border-color: $color-red-dark;
    }

    & + & {
      margin-top: $margin * 2;
    }
  }

  &-item {
    padding: $padding*2 0;

    & + & {
      border-top: solid 1px $color-gray-lighter;
    }

    & > * + * {
      margin-top: $margin;
    }

    @media screen and (min-width: 1000px){
      display: flex;
      align-items: center;     
    }

    &-content {
      @media screen and #{$mq-min-md} {
        flex: 2;
      }

      &-primary {
        font-weight: bold;
      }
    }

    &-due {
      display: inline-block;
      background-color: $color-yellow-dark;
      padding: 3px $padding;

      .expired & {
        background-color: $color-red-dark;
        color: white;
      }

      @media screen and (min-width: 1000px) {
        margin: 0 $margin*2;
      }
    }

    &-action {
      margin-top: $margin;

      @media screen and #{$mq-min-md} {
        margin-left: auto;
      }
    }
  }
}
