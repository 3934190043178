@import '~texkit/dist/styles/variables';

.navbar-auth {
  z-index: $z-index-navbar;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);
  transition: $transition-very-slow;

  &.navbar-enter {
    transform: translate3d(-100%, 0, 0);
    transition-delay: 0.015s;
  }
  &.navbar-enter-active,
  &.navbar-enter-done {
    transform: translate3d(0, 0, 0);
  }

  &.navbar-exit-active,
  &.navbar-exit-done {
    transform: translate3d(-100%, 0, 0);

    @media #{$mq-min-md} {
      transform: translate3d(0, 0, 0);
    }
  }

  .navbar {
    background-color: $color-blue-dark;
    max-width: $max-width-screen;
    margin: 0 auto;

    .navbar-container {
      @media #{$mq-max-sm} {
        padding-right: $padding;
      }
    }

    .navbar-right {
      padding-right: 48px;

      @media #{$mq-min-md} {
        padding-right: 0;
      }
    }
  }
}
