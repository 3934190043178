@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/colors';
@import '~texkit/dist/styles/placeholders';

.message-banners {
  list-style: none;
  margin: 0;
  padding: 0;
  background: $color-blue-dark;
  overflow-x: hidden;
}

.message-banner {
  padding-top: $padding;
  padding-bottom: $padding;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;

  &-exit {
    transform: translateX(0);
  }
  &-exit-active {
    transform: translateX(-100vw);
    transition: transform 250ms ease-in-out;
  }

  & > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width-container;

    padding-left: $padding*2;
    padding-right: $padding*2;

    @media screen and #{$mq-min-md} {
      padding-left: $padding*3;
      padding-right: $padding*3;
    }
    @media screen and #{$mq-min-lg} {
      padding-left: $padding*4;
      padding-right: $padding*4;
    }

    .button-icon {
      flex-shrink: 0;
      margin-left: $margin * 2;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    & > p + p {
      margin-top: 1.5em;
    }

  }

  button {
    border: 0;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
  }


  &-1 {
    color: $color-white;
    background-color: $color-red-dark;
  }
  &-2 {
    color: $color-black;
    background-color: $color-yellow-dark;
  }
  &-3 {
    color: $color-white;
    background-color: $color-gray;
  }
  &-4 {
    color: $color-black;
    background-color: #dfe5f4;
  }
}

@keyframes swipe-out {
  0% {
    height: auto;
    transform: translateX(0);
  }

  50% {
    height: auto;
    transform: translateX(-100vw);
  }

  100% {
    height: 0;
    transform: translateX(-100vw);
  }
}
