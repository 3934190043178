@import '~texkit/dist/styles/variables';

.unlink-service-modal {
  .modal-title,
  .modal-content {
    max-width: 624px;
  }

  .lead {
    margin-bottom: $margin * 4;
  }

  &-list {
    margin: 0 auto;
  }

  .summary-card {
    border-radius: 0;
    border-top: 1px solid $color-gray-light;

    margin: 0;

    &:last-of-type {
      border-bottom: 1px solid $color-gray-light;
    }
  }
}
